.flip-card-left {
  perspective: 1000px;
  box-shadow: 24;
  width: 350px;
  height: 350px;
  /* margin-left: 20%; */
}

.flip-card-inner-left {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: 1px solid grey;
  border-radius: 8px;
}

.flip-card-left:hover .flip-card-inner-left {
  transform: rotateY(180deg);
  cursor: pointer;
}

.flip-card-front-left, .flip-card-back-left {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-left {
  color: black;
}

.flip-card-back-left {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
  border-radius: 8px;
}

.flip-card-right {
  perspective: 1000px;
  box-shadow: 24;
  width: 350px;
  height: 350px;
  margin-left: 20%;
}

.flip-card-inner-right {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: 1px solid grey;
  border-radius: 8px;
}

.flip-card-right:hover .flip-card-inner-right {
  transform: rotateY(180deg);
  cursor: pointer;
}

.flip-card-front-right, .flip-card-back-right {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-right {
  color: black;
}

.flip-card-back-right {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
  border-radius: 8px;
}

.containerMainMenu {
  position: relative;
  /* background-color: #2f3542; */
  height: 100vh;
  width: 100%;
  background-size: cover;
}

.containerGrid {
  display: flex;
  justify-content: center;
}

.containerCardLeft {
  box-shadow: 24;
  width: 500px;
  margin-left: 20%;
}

.containerCardLeft:hover .wrapActionArea {
  transform: rotateY(180deg);
}

.containerCardRight {
  box-shadow: 24;
  width: 500px;
  margin-right: 20%;
}

.wrappMainMenu {
  /* position: absolute; */
  margin-top: 6%;
  width: 100%;
  /* margin-left: 50%; */
  /* margin-right: 20px; */
}

.animatedText {
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  padding-top: 20px;
  animation: 5s cursor infinite;
}

.animatedText span {
  position: relative;
}

/* .animatedText span::before { */
  /* content: "Selamat Datang Di Pendaftaran Pasien Rawat Jalan"; */
  /* color: #ff7f50; */
/* } */

/* .animatedText span::after {
  content: "";
  position: absolute;
  width: calc(100% + 8px);
  height: 100%;
  background-color: #2f3542;
  border-left: 2px solid #ff7f50;
  right: -8px;
  animation: .8s cursor infinite;
} */

@keyframes cursor {
  0% { color: #FFF; }
  25% { color: #dbdbdb }
  50% { color: #b5b5b5; }
  75% { color: #616161; }
  100% { color: #282828; }
  /* from {
    color: #FFF;
  }
  to { color: #616161; } */
  /* to {
    border-left: 2px solid #ff7f5000;
  } */
}

@keyframes typing {

  10%,
  15%,
  30%,
  35%,
  50%,
  55%,
  70%,
  75%,
  90%,
  95% {
    width: 0px;
  }

  5%,
  20%,
  25%,
  40%,
  45%,
  60%,
  65%,
  80%,
  85% {
    width: calc(100% + 8px);
  }
}